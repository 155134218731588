import { useState, useEffect, useContext } from 'react';
import { Box, Button, Dialog, DialogTitle, TextField, Typography } from '@mui/material';
import api from '../api';
import context from '../context';
import style from '../style';
import { br, hash } from '../resources';

export default () => {
    const { set, loginDialogOpen, setLoginDialogOpen } = useContext(context);

    const initDialogLogin = {
        open: false,
        reset: false,
        email: '',
        creds: { userName: '', password: '' },
        text: {
            creds: { value: 'Enter your credentials', color: null },
            email: { value: 'Enter your Email', color: null, disabled: false }
        }
    };

    const [loginDialog, setLoginDialog] = useState(initDialogLogin);

    const setDialogLogin = {
        open: () => setLoginDialog({ ...loginDialog, open: true }),
        close: () => setLoginDialog(initDialogLogin),
        reset: reset => setLoginDialog({ ...loginDialog, reset }),
        email: email => setLoginDialog({ ...loginDialog, email }),

        creds: {
            userName: userName => setLoginDialog({ ...loginDialog, creds: { ...loginDialog.creds, userName } }),
            password: password => setLoginDialog({ ...loginDialog, creds: { ...loginDialog.creds, password } })
        },

        text: {
            creds: creds => setLoginDialog({ ...loginDialog, text: { ...loginDialog.text, creds } }),
            email: email => setLoginDialog({ ...loginDialog, text: { ...loginDialog.text, email } })
        }
    };

    useEffect(() => {
        if (loginDialogOpen) {
            setDialogLogin.open();
            setLoginDialogOpen(false);
        }
    }, [loginDialogOpen]);

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(loginDialog.reset && loginDialog.text.email.disabled);
    }, [loginDialog]);

    const notFound = text => `User with ${text} was not found`;

    const color = success => success ? 'greenyellow' : 'orangered';

    const handle = {
        confirm: async () => {
            if (loginDialog.reset) {
                const { email } = loginDialog;
                const result = await api.person.reset.a(email);
                setDialogLogin.text.email({
                    value: result ? 'Check out your Email' : notFound('this Email'),
                    color: color(result),
                    disabled: result
                });
            } else {
                const { creds } = loginDialog;
                const result = await set.me.login({ ...creds, password: hash(creds.password) });
                result ? setDialogLogin.close() : setDialogLogin.text.creds({
                    value: notFound('these credentials'),
                    color: color()
                });
            }
        }
    };

    return <>
        <Dialog PaperProps={{ style: style.color.default }} fullWidth onClose={setDialogLogin.close} open={loginDialog.open}>
            <DialogTitle style={style.center()}>{loginDialog.reset ? 'Restore credentials via Email' : 'Sign In'}</DialogTitle>
            <Box><div style={style.flex('center')}><center>
                {loginDialog.reset ? <TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label="Email" disabled={loginDialog.text.email.disabled} value={loginDialog.email} onChange={e => setDialogLogin.email(e.target.value)}/> : <>
                    <TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label="User Name" value={loginDialog.creds.userName} onChange={e => setDialogLogin.creds.userName(e.target.value)}/>
                    <TextField sx={style.sx} InputProps={style.inputProps} variant="outlined" style={style.mg} label="Password" value={loginDialog.creds.password} onChange={e => setDialogLogin.creds.password(e.target.value)} type="password"/>
                </>}
                {br()}
                <Button color="inherit" variant="outlined" style={disabled ? style.color.gray : style.color.green.light} onClick={handle.confirm} disabled={disabled}>confirm</Button>
                {br(20)}
                {loginDialog.reset
                    ? <Typography color={loginDialog.text.email.color}>{loginDialog.text.email.value}</Typography>
                    : <Typography color={loginDialog.text.creds.color}>{loginDialog.text.creds.value}</Typography>}
                {br(20)}
                <Button color="inherit" variant="outlined" style={{ ...style.color.green.dark, ...style.width.min(250) }} onClick={() => setDialogLogin.reset(!loginDialog.reset)}>{loginDialog.reset ? 'back to' : 'restore'} credentials</Button>
                {br(20)}
            </center></div></Box>
        </Dialog>
    </>;
};
