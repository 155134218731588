import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import api from '../api';
import context from '../context';
import style from '../style';
import { go, type } from '../resources';

export default () => {
    const { setLoginDialogOpen, creds, me } = useContext(context);

    const [text, setText] = useState('');
    const [edit, setEdit] = useState(false);

    useEffect(() => { if (!edit) (async () => setText(await api.home.get()))(); }, [edit]);

    const handle = {
        body: {
            text: e => type(e, 'Text', setText, 3000),

            confirm: async () => {
                await api.home.update(creds, text);
                setEdit(false);
            }
        },

        sign: {
            in: () => setLoginDialogOpen(true),
            up: go('profile')
        }
    };

    const sign = sign => <>
        <Grid xs={4} style={{ marginBottom: 50 }}><center>
            <Grid xs={12}><Typography variant="h6" style={style.margin.tb(50)}>{sign == 'up' ? 'Don\'t have an account yet?' : 'Already have an account?'}</Typography></Grid>
            <Grid xs={12}><Button color="inherit" variant="outlined" style={style.color.green.dark} onClick={handle.sign[sign]}>sign {sign}</Button></Grid>
        </center></Grid>
    </>;

    return <>
        <Typography variant="h5" style={{ ...style.center(), ...style.margin.tb(50), ...style.clr('lime') }}>Welcome to Chatterbox!</Typography>
        <Box><Grid container>
            <Grid xs={2}/>
            <Grid xs={8}>
                {me.admin && edit
                    ? <TextField sx={style.sx} InputProps={style.inputProps} fullWidth multiline rows={15} placeholder="Type text here..." value={text} onChange={handle.body.text}/>
                    : text.split('\n').filter(Boolean).map(paragraph => <Typography paragraph align="justify">{paragraph}</Typography>)}
                {me.admin && edit ? <div style={style.flex('space-between')}>
                    <Button color="inherit" variant="outlined" style={{ ...style.color.red, ...style.margin.left(0) }} onClick={() => setEdit(false)}>cancel</Button>
                    <Button color="inherit" variant="outlined" style={{ ...style.color.green.light, ...style.margin.right(0) }} onClick={handle.body.confirm}>confirm</Button>
                </div> : <></>}
                {me.admin && !edit ? <div style={style.flex('right')}>
                    <Button color="inherit" variant="outlined" style={{ ...style.color.green.dark, ...style.margin.right(0) }} onClick={() => setEdit(true)}>edit</Button>
                </div> : <></>}
            </Grid>
            <Grid xs={2}/>
        </Grid></Box>
        {me.userName ? <></> : <Box><Grid container><Grid xs={2}/>{sign('up')}{sign('in')}<Grid xs={2}/></Grid></Box>}
    </>;
};
