import { Avatar, Badge, Button, Divider, ListItemAvatar, Typography } from '@mui/material';
import crypto from 'crypto-js';
import style from './style';

export const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const sleep = seconds => new Promise(resolve => setTimeout(resolve, seconds * 1000));

export const absCenter = (inner, color) => <center style={style.abs.center}>
    {typeof inner == 'string' ? <Typography variant="h5" style={style.clr(color)}>{inner}</Typography> : inner}
</center>;

export const avaPath = id => `/public/avatar/${id}.jpg`;

export const avatar = (id, online) => {
    const ava = <Avatar style={style.bd} src={avaPath(id)}/>;

    return <ListItemAvatar>{online ? <Badge sx={style.sx} overlap="circular" badgeContent=" " anchorOrigin={{
        vertical: 'bottom', horizontal: 'right'
    }}>{ava}</Badge> : ava}</ListItemAvatar>;
};

export const br = height => <div style={style.height.exact(height)}/>;

export const btn = (click, text, style, disabled) => <Button color="inherit" variant="outlined" style={style} onClick={click} disabled={disabled}>{text}</Button>;

export const btnText = text => <Typography variant="button" display="block" align="center" margin={3}>{text}</Typography>;

export const debug = {
    enabled: window.localStorage.getItem('debug') == 'true',
    log: (...message) => debug.enabled && console.log('>>>', ...message)
};

export const divider = (i, color) => i ? <Divider sx={{ borderTopWidth: 1 }} style={style.bgc(color || 'black')}/> : <></>;

export const getReady = (setReady, home) => [() => {
    (async () => {
        await sleep(5);
        setReady(true);

        if (home) {
            await sleep(10);
            go()();
        }
    })();
}, []];

export const go = path => () => window.location.pathname = path ? `/${path}` : '';

export const hash = data => crypto.enc.Hex.stringify(crypto.SHA512(data));

export const onCall = window.location.pathname.startsWith('/call');

export const pair = {
    key: x => Object.keys(x)[0],
    val: x => Object.values(x)[0]
};

export const randomNum = num => 1 + Math.trunc(Math.random() * num);

export const strStart = (str, limit) => {
    if (!str) return str;
    str = str.trim().replace(/\s+/g, ' ');
    if (str.length <= limit) return str;
    const substr = str.substring(0, limit);
    if (str.substring(limit - 1, limit + 1).includes(' ')) return substr.trim();
    return `${substr}.`;
};

export const type = (e, name, set, limit) => {
    const t = e.target.value;
    t.length > limit ? alert(`Length limit (${limit} characters) is exceeded for the "${name}" input!`) : set(t);
};

export const resolutionList = {
    '16:9': [
        [640, 360],
        [1280, 720],
        [1360, 768],
        [1366, 768],
        [1536, 864],
        [1600, 900],
        [1920, 1080],
        [2048, 1152],
        [2560, 1440],
        [3840, 2160]
    ],

    '16:10': [
        [1280, 800],
        [1440, 900],
        [1680, 1050],
        [1920, 1200],
        [2560, 1600]
    ],

    '4:3': [
        [640, 480],
        [800, 600],
        [1024, 768],
        [1600, 1200],
        [2048, 1536]
    ],

    '5:4': [
        [1280, 1024]
    ],

    '21:9': [
        [2560, 1080],
        [3440, 1440]
    ]
};
