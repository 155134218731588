import { useContext } from 'react';
import context from '../context';
import Home from './home';

export default props => {
    const { Component } = props;

    const { me } = useContext(context);

    return me.userName ? <Component/> : <Home/>;
};
