import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import api from '../api';
import context from '../context';
import { absCenter, getReady } from '../resources';

export default () => {
    const { userName, code } = useParams();

    const { me } = useContext(context);

    const [ready, setReady] = useState(false);

    const [state, setState] = useState(['Please wait...']);

    const reset = async () => {
        if (ready) me.userName
            ? setState(['You are already signed in!'])
            : setState((succeded => [
                `Password reset has been ${succeded ? 'succeeded! Check out your Email.' : 'failed!'}`,
                succeded ? 'greenyellow' : 'orangered'
            ])(await api.person.reset.b(userName, code)));
    };

    useEffect(...getReady(setReady, true));

    useEffect(() => { reset(); }, [ready]);

    return absCenter(...state);
};
