import { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';
import api from '../api';
import context from '../context';
import style from '../style';
import { avatar, onCall, randomNum } from '../resources';

export default () => {
    const { socket, set, callers, setCallers, goCall } = useContext(context);

    const [calls, setCalls] = useState([]);
    const [ringtone, setRingtone] = useState(<></>);

    const ringtoneAudio = <audio loop id="ringtone">
        <source src={`${process.env.PUBLIC_URL}/audio/PhoneRing${randomNum(2)}-silence-2s.wav`} type="audio/wav"/>
    </audio>;

    useEffect(() => {
        if (!onCall) {
            socket.on('call', userName => setCallers([...callers, userName]));
            socket.on('done', userName => setCallers(callers.filter(caller => caller != userName)));
        }

        const creds = JSON.parse(window.localStorage.getItem('creds'));
        (async () => { if (await set.me.login(creds)) socket.emit('online', creds); })();
    }, []);

    useEffect(() => {
        (async () => {
            const result = [];

            for (const i in callers) {
                const person = await api.get.one(callers[i]);
                if (!person) continue;
                const { id, userName, firstName, lastName } = person;

                result.push(<>
                    <Box sx={{ border: 1, borderRadius: 2 }} style={{ ...style.color.green.dark, position: 'fixed', right: 0, bottom: 170 * i, height: 150, width: 300, zIndex: 1 }}>
                        <Typography style={style.center(undefined, 10)}>Incoming call</Typography>
                        <div style={{ ...style.mg, ...style.flex('center', 'center') }}>
                            {avatar(id)}<Typography>{firstName} {lastName}</Typography>
                        </div>
                        <Box style={{ ...style.flex('space-between'), position: 'absolute', bottom: 0, width: '100%' }}>
                            <Button color="inherit" variant="outlined" style={style.color.red} onClick={() => socket.emit('decline', userName)}>decline</Button>
                            <Button color="inherit" variant="outlined" style={style.color.green.light} onClick={goCall(userName)}>accept</Button>
                        </Box>
                    </Box>
                </>);
            }

            setCalls(result);
            setRingtone(result.length ? ringtoneAudio : <></>);
        })();
    }, [callers]);

    useEffect(() => {
        const sound = document.getElementById('ringtone');
        if (sound) sound.play();
    }, [ringtone]);

    return <>{calls}{ringtone}</>;
};
