import { useContext } from 'react';
import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material';
import context from '../context';
import style from '../style';
import { debug, go, onCall } from '../resources';
import Login from './login';
import Ring from './ring';

debug.log('DEBUG ENABLED');

export default () => {
    const { set, setLoginDialogOpen, creds } = useContext(context);

    const btn = (click, text) => <Button color="inherit" variant="outlined" style={
        window.location.pathname.substring(1) == click ? style.color.green.light : style.mg
    } onClick={typeof click == 'string' && go(click) || click}>
        {text || typeof click == 'string' && click}
    </Button>;

    return <>
        <Login/>
        <Ring/>
        <Box><AppBar sx={{ border: 1, borderRadius: 2 }} position="static" style={{ ...style.color.green.dark, margin: 0, marginBottom: 30, display: onCall && 'none', zIndex: 10 }}><Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}><div style={style.ptr} onClick={go()}>CHATTERBOX</div></Typography>
            {creds.userName ? [
                btn('calendar'),
                btn('users'),
                btn('profile'),
                btn(set.me.logout, 'sign out')
            ] : [
                btn('profile', 'sign up'),
                btn(() => setLoginDialogOpen(true), 'sign in')
            ]}
        </Toolbar></AppBar></Box>
    </>;
};
