import { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, List, ListItem, ListItemText } from '@mui/material';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import context from '../context';
import style from '../style';
import { dateInfo, nextDate, sundayDate } from './time.const';

export default () => {
    const { me } = useContext(context);

    const [timeTable, setTimeTable] = useState([]);

    const [start, setStart] = useState(sundayDate());

    const handle = {
        move: {
            prev: () => {
                const d = new Date(start.date);
                const prev = new Date(d.setDate(d.getDate() - 7));
                setStart(dateInfo(prev));
            },

            current: () => setStart(sundayDate()),

            next: () => {
                const d = new Date(start.date);
                const next = new Date(d.setDate(d.getDate() + 7));
                setStart(dateInfo(next));
            }
        }
    };

    useEffect(() => {
        getTimeTable();
    }, [me, start]);

    const getTimeTable = async () => {
        const week = [];
        let day = start;

        for (let k = 0; k < 14; k++) {
            const clr = {
                back: 'dimgray',
                call: 'darkgreen'
            };

            const list = [];

            if (!(k % 7)) week.push(<Grid xs={1}/>);
            week.push(<>
                <Grid xs={1.42}><List>
                    <ListItem><ListItemText style={style.center()}>{day.name}<br/>{day.str}</ListItemText></ListItem>
                    <div style={style.scroll(320, clr.back)}>{list}</div>
                </List></Grid>
                <Grid xs={0.01}/>
            </>);
            if (!((k + 1) % 7)) week.push(<Grid xs={0.99}/>);

            day = nextDate(day.date);
        }

        setTimeTable(week);
    };

    const paginator = <>
        <div style={style.flex('center')}>
            <Button color="inherit" variant="outlined" style={style.color.green.dark} onClick={handle.move.prev} startIcon={<NavigateBefore/>}>prev</Button>
            <Button color="inherit" variant="outlined" style={start.start == sundayDate().start ? style.color.green.light : style.color.green.dark} onClick={handle.move.current}>current</Button>
            <Button color="inherit" variant="outlined" style={style.color.green.dark} onClick={handle.move.next} endIcon={<NavigateNext/>}>next</Button>
        </div>
    </>;

    return <>{paginator}<Box><Grid container>{timeTable}</Grid></Box></>;
};
