const time = date => Math.round(date.getTime() / 900000);

export const now = () => time(new Date());

export const dateInfo = date => {
    const start = time(date);

    return {
        date,
        name: date.toLocaleString('en-US', { weekday: 'long' }),
        str: date.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }).split(',').join(' /'),
        start,
        end: start + 96
    };
};

export const timeDate = time => {
    const date = new Date(1970, 0, 1);
    date.setMinutes(time * 15);
    return dateInfo(date);
};

export const sundayDate = () => {
    const d = new Date();
    const date = new Date(new Date(d.setDate(d.getDate() - d.getDay())).toDateString());
    return dateInfo(date);
};

export const nextDate = date => {
    const d = new Date(date);
    const next = new Date(d.setDate(d.getDate() + 1));
    return dateInfo(next);
};

export const timeZoneDiff = () => (new Date()).getTimezoneOffset() / 15;

export const timeZoneDate = time => timeDate(time - timeZoneDiff());

export const display = {
    time: time => {
        const t = (time - timeZoneDiff()) % 96;
        const quarter = t % 4;
        const hour = (t - quarter) / 4;
        return `${hour < 10 ? '0' : ''}${hour}:${quarter ? quarter * 15 : '00'}`;
    },

    interval: interval => `${display.time(interval.start)} - ${display.time(interval.end)}`
};

export const Interval = {
    zero: interval => interval.start == interval.end,
    before: (first, second) => first.end <= second.start,
    inside: (first, second) => second.start <= first.start && first.end <= second.end,
    sort: (first, second) => Interval.before(first, second) ? -1 : Number(Interval.before(second, first)),

    intersection: (first, second) => {
        if (Interval.sort(first, second)) return null;

        return {
            start: first.start < second.start ? second.start : first.start,
            end: first.end < second.end ? first.end : second.end
        };
    },

    cut: (interval, intervals) => {
        const t = interval;
        const result = [];

        for (const i of intervals) if (!Interval.sort(i, t)) {
            if (Interval.inside(t, i)) return null;
            if (i.start <= t.start) t.start = i.end;
            if (t.end <= i.end) t.end = i.start;
            if (Interval.inside(i, t)) {
                result.push({ start: t.start, end: i.start });
                t.start = i.end;
            }
        }

        result.push(t);
        return result;
    }
};
