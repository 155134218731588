import { useState, useEffect, useContext } from 'react';
import { Box, Grid, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import api from '../api';
import context from '../context';
import style from '../style';
import { absCenter, avatar, divider } from '../resources';

export default () => {
    const { socket, me, goCall } = useContext(context);
    const [users, setUsers] = useState([]);

    const fetchData = async () => {
        const data = await api.get.all();
        setUsers(data.rows.filter(user => user.id != me.id));
    };

    useEffect(() => { me.userName && fetchData(); }, [me]);

    const PersonItem = ({ person }) => {
        const { id, userName, firstName, lastName } = person;
        const [online, setOnline] = useState(false);

        socket.on(`online#${userName}`, setOnline);
        socket.emit('online#', userName);

        return <>
            <ListItem>
                <ListItemButton style={style.squareBtn} onClick={goCall(userName)}><PhoneTwoToneIcon htmlColor="greenyellow"/></ListItemButton>
                <ListItemButton>
                    {avatar(id, online)}
                    <ListItemText>{firstName} {lastName}</ListItemText>
                </ListItemButton>
            </ListItem>
        </>;
    };

    const personList = () => users.map((person, i) => <>{divider(i)}<PersonItem person={person}/></>);

    return users.length ? <>
        <Box><Grid container>
            <Grid xs={1}/>
            <Grid xs={7}><div style={style.scroll(400)}><List>
                {personList()}
            </List></div></Grid>
            <Grid xs={4}/>
        </Grid></Box>
    </> : absCenter('Users Not Found');
};
