const px = px => `${px}px solid greenyellow`;

const bd = { border: px(1) };

const mg = { margin: 10 };

const margin = {
    left: marginLeft => ({ marginLeft }),
    right: marginRight => ({ marginRight }),
    top: marginTop => ({ marginTop }),
    bottom: marginBottom => ({ marginBottom }),
    lr: margin => ({ marginLeft: margin, marginRight: margin }),
    tb: margin => ({ marginTop: margin, marginBottom: margin })
};

margin.bgc = (backgroundColor, color) => ({ ...mg, backgroundColor, color });
margin.float = float => ({ ...mg, float });
margin.min = minWidth => ({ ...mg, minWidth });
margin.ava = { ...bd, ...mg, width: 200, height: 200 };

const padding = {
    left: paddingLeft => ({ paddingLeft }),
    right: paddingRight => ({ paddingRight }),
    top: paddingTop => ({ paddingTop }),
    bottom: paddingBottom => ({ paddingBottom }),
    lr: padding => ({ paddingLeft: padding, paddingRight: padding }),
    tb: padding => ({ paddingTop: padding, paddingBottom: padding })
};

const column = { flexDirection: 'column' };
const link = { cursor: 'pointer', textDecoration: 'underline', color: 'dodgerblue' };
const none = { display: 'none' };
const ptr = { cursor: 'pointer', display: 'inline' };
const bgc = (backgroundColor, color) => ({ backgroundColor, color });
const center = (backgroundColor, marginTop) => ({ backgroundColor, marginTop, textAlign: 'center' });
const clr = color => ({ color });
const flex = (justifyContent, alignItems) => ({ display: 'flex', justifyContent, alignItems });
const float = float => ({ float });
const hl = (highlight, color) => ({ backgroundColor: highlight ? color?.highlight || 'Highlight' : color?.background || 'Background' });
const scroll = (height, backgroundColor) => ({ height, backgroundColor, overflow: 'auto' });

const height = {
    exact: height => ({ height }),
    max: maxHeight => ({ maxHeight }),
    min: minHeight => ({ minHeight })
};

const width = {
    exact: width => ({ width }),
    max: maxWidth => ({ maxWidth }),
    min: minWidth => ({ minWidth })
};

const abs = {
    center: { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, margin: 'auto', height: 'fit-content' },
    bgc: (backgroundColor, objectFit) => ({ backgroundColor, objectFit, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' })
};

const color = {
    halfBlack: bgc('rgba(0, 0, 0, 0.5)'),

    gray: margin.bgc('gray', 'black'),

    green: {
        light: margin.bgc('forestgreen', 'greenyellow'),
        dark: margin.bgc('darkgreen', 'lime')
    },

    red: margin.bgc('orangered', 'black'),

    default: margin.bgc('darkslategray', 'navajowhite')
};

const squareBtn = ({ ...bd, ...flex('center'), ...bgc('forestgreen'), ...width.max(60) });

const textarea = {
    caretColor: 'orangered',
    fontFamily: 'monospace',
    backgroundColor: 'transparent',
    color: 'limegreen',
    border: 'none',
    padding: '10px',
    resize: 'none',
    outline: 'none'
};

const video = (right, bottom, [width, height]) => ({ ...bd, ...margin.bgc('black'), [right ? 'right' : 'left']: 0, [bottom ? 'bottom' : 'top']: 0, position: 'fixed', width, height });

const inputProps = { style: { color: 'navajowhite' } };

const sx = {
    '& label': {
        '&.MuiInputLabel-root': clr('lime'),
        '&.Mui-focused': clr('greenyellow')
    },

    '& .MuiOutlinedInput-root': {
        '& fieldset': { borderColor: 'forestgreen' },
        '&:hover fieldset': { borderColor: 'limegreen' },
        '&.Mui-focused fieldset': { borderColor: 'limegreen' }
    },

    '& .MuiInput-underline': {
        '&:before': { borderBottomColor: 'lime' },
        '&:after': { borderBottomColor: 'greenyellow' }
    },

    '& .MuiSelect-icon': clr('orangered'),
    '& .MuiSelect-select': clr('navajowhite'),
    '& .MuiBadge-badge': { ...bgc('orangered'), border: '3px solid black' },
    '& .MuiSlider-thumb': {
        ...bgc('orangered'),
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 0
        }
    },
    '& .MuiSlider-valueLabel': {
        ...bgc('darkgreen', 'lime'),
        ...bd
    }
};

export default { px, bd, padding, mg, margin, column, link, none, ptr, bgc, center, clr, flex, float, hl, scroll, height, width, abs, color, squareBtn, textarea, video, inputProps, sx };
