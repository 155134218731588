import { useState, createContext } from 'react';
import io from 'socket.io-client';
import api from './api';
import { go } from './resources';

const socket = io('/ring');
const context = createContext();
const { Provider } = context;
export default context;

const init = {
    creds: { userName: '', password: '' },

    me: {
        id: '',
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        admin: false,
        verified: false
    }
};

export const ContextProvider = props => {
    const [creds, setCreds] = useState(init.creds);
    const [me, setMe] = useState(init.me);
    const [loginDialogOpen, setLoginDialogOpen] = useState(false);
    const [callers, setCallers] = useState([]);

    const goCall = userName => () => {
        setCallers(callers.filter(caller => caller != userName));
        window.open(`/call/${userName}`, '_blank', 'status=yes');
    };

    const set = {
        me: {
            logout: () => {
                window.localStorage.removeItem('creds');
                setCreds(init.creds);
                setMe(init.me);
                go()();
            },

            login: async creds => {
                if (creds && creds.userName && creds.password) {
                    const me = await api.person.get(creds);

                    if (me) {
                        window.localStorage.setItem('creds', JSON.stringify(creds));
                        setCreds(creds);
                        setMe(me);
                        return true;
                    }
                }

                return false;
            }
        }
    };

    return <Provider value={{ socket, creds, me, loginDialogOpen, setLoginDialogOpen, callers, setCallers, goCall, set, init }}>{props.children}</Provider>;
};
